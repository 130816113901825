import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Spinner from "./components/spinner"
import { APIContextProvider } from './context';
import "./styles/themes/default/theme.scss";

const LazyComponent = React.lazy(() => import('./components/app'));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <APIContextProvider>
    <Suspense fallback={<Spinner />}>
      <LazyComponent />
    </Suspense>
  </APIContextProvider>,
);

reportWebVitals();
