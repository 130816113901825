import React,{ useContext, createContext, useState, useRef } from "react";
import useLocalstorage from "../hooks/useLocalStorage";

type APIContextTypes = {
    isModal : boolean, 
    setIsModal : (a : boolean) => void,
    myRef : null | React.MutableRefObject<HTMLDivElement | null>,
    scrolltoref: () => void,
    setContextLS : any, 
    contextLS : any,
    company : any,
    setCompany : any,
    states : any,
    setStates : any,
    icon : any,
    setIcon : any,
    num : number,
    setNum : (a : number) => void,
    load : any, 
    setLoad : any,
    loadAPI : any, 
    setLoadAPI : any,
    error : boolean,
    setIsError : (a: boolean) => void,
    matchingObjects : any, 
    setMatchingObjects : any
};

const APIContext = createContext<APIContextTypes>({
    isModal : false,
    setIsModal : (a: boolean) => {},
    myRef : null,
    scrolltoref: () => {},
    setContextLS : () => {}, 
    contextLS : {},
    company : [] ,
    setCompany : () => {},
    states : [],
    setStates : () => {},
    icon : "",
    setIcon : () => {},
    num : 0,
    setNum : (a : number) => {},
    load : false, 
    setLoad : () => {},
    loadAPI : false, 
    setLoadAPI : () => {},
    error : false,
    setIsError : (a: boolean) => {},
    matchingObjects : () => {},
    setMatchingObjects : []
})

export const APIContextProvider: React.FC<any> = ({ children }) => {
  
  const myRef = useRef<any>(null);
  const [isModal, setIsModal] = useState(false);
  const [company, setCompany] = useState([]);
  const [states, setStates] = useState([{}]);
  const [icon, setIcon] = useState("");
  const [num, setNum] = useState(0);
  const [load, setLoad] = useState(false);
  const [loadAPI, setLoadAPI] = useState(false);
  const [error, setIsError] = useState(false);
  const [matchingObjects, setMatchingObjects] = useState([])

  const { setContextLS, contextLS } = useLocalstorage();

  const scrolltoref = () => {
    if (myRef && myRef?.current !== null) {
      window.scrollTo({
        top: myRef.current.offsetTop - 100,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  return (
    <APIContext.Provider
      value={{
        isModal, 
        setIsModal,
        scrolltoref,
        myRef,
        setContextLS, 
        contextLS,
        company, 
        setCompany,
        states, 
        setStates,
        icon, 
        setIcon,
        num, 
        setNum,
        load, 
        setLoad,
        loadAPI, 
        setLoadAPI,
        error, 
        setIsError,
        matchingObjects, 
        setMatchingObjects
      }}
    >
        {children}
    </APIContext.Provider>
  );
};

export function useTransfer() {
  const context = useContext(APIContext);
  return context;
}