import { useEffect, useState } from "react"
import { LOCAL_STORAGE } from "../enums/localStorage";

const useLocalStorage = () => {
  
    const [contextLS, setContextLS] = useState({
        id: String(window.localStorage.getItem(LOCAL_STORAGE.USER_ID) || ''),
        token: String(window.localStorage.getItem(LOCAL_STORAGE.USER_TOKEN) || ''),
        key: String(window.localStorage.getItem(LOCAL_STORAGE.USER_KEY) || ''),
      });
      
      useEffect(() => {
        window.localStorage.setItem("id", contextLS.id);
        window.localStorage.setItem("token", contextLS.token);
        window.localStorage.setItem("key", contextLS.key);
      }, [contextLS]);
  
    return { contextLS, setContextLS }

}

export default useLocalStorage;